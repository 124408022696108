import { named, withDependencies } from '@wix/thunderbolt-ioc'
import {
	IRoutingMiddleware,
	ICurrentRouteInfo,
	DynamicPagesAPI,
	FetchParams,
	UrlHistoryManagerSymbol,
	IUrlHistoryManager,
} from 'feature-router'
import { DynamicPagesSiteConfig, RouterFetchData, IDynamicPagesWarmupData } from './types'
import {
	BrowserWindow,
	BrowserWindowSymbol,
	Fetch,
	HeadContentSymbol,
	IFetchApi,
	IHeadContent,
	SiteFeatureConfigSymbol,
	ViewerModel,
	Experiments,
	CurrentRouteInfoSymbol,
	ExperimentsSymbol,
	ILogger,
	LoggerSymbol,
} from '@wix/thunderbolt-symbols'
import { ISessionManager, SessionManagerSymbol } from 'feature-session-manager'
import { name } from './symbols'
import { isSSR } from '@wix/thunderbolt-commons'
import { IWarmupDataProvider, WarmupDataProviderSymbol } from 'feature-warmup-data'

enum DynamicRequestTypes {
	PAGES = 'pages',
	SITEMAP = 'sitemap',
}

const isExternalUrl = (url: string): boolean => /(^https?)|(^data)|(^blob)|(^\/\/)/.test(url)

const getPathParts = (relativeUrl: string): Array<string> => relativeUrl.replace('./', '').split('/')

const getRouterSuffix = (relativeEncodedUrl: string): string => {
	// We need to use relativeEncodedUrl because router suffix can contain any charecter,
	// and in case of suffix with slash we have to use encoded value to prevent splitting the suffix to two url parts
	// e.g http://mysite.com/router-1/good%2Fevil -> relativeUrl will be './router-1/good/evil'
	// and relativeEncodedUrl will be './router-1/good%2Fevil'
	const remainingPathParts = getPathParts(relativeEncodedUrl).slice(1)

	return `/${remainingPathParts.join('/')}`
}

const getPathFirstPart = (relativeUrl: string): string => getPathParts(relativeUrl)[0]

const getBody = (
	routerFetchData: RouterFetchData,
	relativeEncodedUrl: string,
	queryParams: string,
	externalBaseUrl: string,
	viewMode: ViewerModel['viewMode']
): string => {
	const { routerPrefix, config, pageRoles } = routerFetchData.optionsData.bodyData
	const routerSuffix = getRouterSuffix(relativeEncodedUrl)
	const fullUrl = `${externalBaseUrl}${routerPrefix}${routerSuffix}${queryParams}`

	return JSON.stringify({
		routerPrefix,
		routerSuffix,
		fullUrl,
		config,
		pageRoles,
		requestInfo: {
			formFactor: viewMode,
		},
	})
}

const addQueryParam = (url: string, paramName: string, paramValue: string): string => {
	const parsedUrl = new URL(url)
	parsedUrl.searchParams.append(paramName, paramValue)

	return parsedUrl.toString()
}

const getFetchParams = (
	routerFetchData: RouterFetchData,
	viewMode: ViewerModel['viewMode'],
	sessionManager: ISessionManager,
	relativeEncodedUrl: string,
	extraQueryParams: string,
	externalBaseUrl: string,
	requestType: DynamicRequestTypes
): FetchParams => {
	const { basePath, queryParams, appDefinitionId } = routerFetchData.urlData
	const url = `${basePath}/${requestType}?${queryParams}`

	const instance = sessionManager.getAppInstanceByAppDefId(appDefinitionId) as string
	const urlWithInstance = addQueryParam(url, 'instance', instance)
	return {
		url: urlWithInstance,
		options: {
			method: 'POST',
			body: getBody(routerFetchData, relativeEncodedUrl, extraQueryParams, externalBaseUrl, viewMode),
			headers: routerFetchData.optionsData.headers,
			...(routerFetchData.optionsData.credentials
				? { credentials: routerFetchData.optionsData.credentials }
				: {}),
			...(routerFetchData.optionsData.mode ? { mode: routerFetchData.optionsData.mode } : {}),
		},
	}
}

const errorPagesIds = {
	FORBIDDEN: '__403__dp',
	NOT_FOUND: '__404__dp',
	INTERNAL_ERROR: '__500__dp',
	UKNOWN_ERROR: '__uknown_error__dp',
}

const FORBIDDEN = 403
const NOT_FOUND = 404

const getErrorPageId = (
	{ status, page, redirectUrl }: { status: number; page: string; redirectUrl: string },
	exception: string | undefined
): string | null => {
	if (exception) {
		return errorPagesIds.INTERNAL_ERROR
	}

	if (status === FORBIDDEN) {
		return errorPagesIds.FORBIDDEN
	}

	if (status === NOT_FOUND) {
		return errorPagesIds.NOT_FOUND
	}

	if (!page && !redirectUrl) {
		return errorPagesIds.UKNOWN_ERROR
	}

	return null
}

const getRelativeUrl = (redirectUrl: string, routerPrefix: string): string =>
	/^\/(.*)/.test(redirectUrl) ? `.${redirectUrl}` : `./${routerPrefix}/${redirectUrl}`

const getAbsoluteUrl = (redirectUrl: string, baseUrl: string, routerPrefix: string, searchParams: string): string => {
	if (isExternalUrl(redirectUrl)) {
		return redirectUrl
	}

	const relativeUrl = getRelativeUrl(redirectUrl, routerPrefix)
	const absoluteUrl = new URL(relativeUrl, `${baseUrl}/`)
	absoluteUrl.search = searchParams

	return absoluteUrl.toString()
}

const dynamicPagesFactory = (
	{ prefixToRouterFetchData, routerPagesSeoToIdMap, externalBaseUrl, viewMode }: DynamicPagesSiteConfig,
	browserWindow: BrowserWindow,
	sessionManager: ISessionManager,
	headContent: IHeadContent,
	fetchApi: IFetchApi,
	currentRouteInfo: ICurrentRouteInfo,
	urlHistoryManager: IUrlHistoryManager,
	warmupDataProvider: IWarmupDataProvider,
	experiments: Experiments,
	logger: ILogger
): IRoutingMiddleware & DynamicPagesAPI => {
	const dangerouslyReportFirstNavigationToDynamicPageUsingFedops = () => {
		// TODO remove when merging specs.thunderbolt.dynamicPagesWarmupData
		const INITIAL_DYNAMIC_PAGE_NAVIGATION = 'initial-dynamic-page-navigation'
		logger.interactionStarted(INITIAL_DYNAMIC_PAGE_NAVIGATION)
		logger.interactionEnded(INITIAL_DYNAMIC_PAGE_NAVIGATION)
	}

	const getWarmupDynamicRouteInfo = () => {
		const currentRoute = currentRouteInfo.getCurrentRouteInfo()
		if (!experiments['specs.thunderbolt.dynamicPagesWarmupData'] || currentRoute) {
			return null
		}

		// we don't want to wait for it, only use it if we already have it (i.e. documentReady)
		return warmupDataProvider.getWarmupData<IDynamicPagesWarmupData>(name, { timeout: 0 })
	}

	const handleSsrRedirect = (redirectUrl: string, routerPrefix: string, routeParsedUrl: URL) => {
		const absoluteRedirectUrl = getAbsoluteUrl(redirectUrl, externalBaseUrl, routerPrefix, routeParsedUrl.search)

		headContent.setHead(`<meta http-equiv="refresh" content="0;url=${absoluteRedirectUrl}" />`)
	}

	return {
		getSitemapFetchParams(routerPrefix) {
			const routerFetchData = prefixToRouterFetchData[routerPrefix]
			if (!routerFetchData) {
				return null
			}

			return getFetchParams(
				routerFetchData,
				viewMode,
				sessionManager,
				urlHistoryManager.getRelativeEncodedUrl(),
				urlHistoryManager.getParsedUrl().search,
				externalBaseUrl,
				DynamicRequestTypes.SITEMAP
			)
		},
		async handle(routeInfo) {
			if (!routeInfo.pageId && routeInfo.relativeUrl && routeInfo.parsedUrl && routeInfo.relativeEncodedUrl) {
				const pathFirstPart = getPathFirstPart(routeInfo.relativeUrl)
				const routerFetchData = prefixToRouterFetchData[pathFirstPart]

				if (!routerFetchData) {
					if (routerPagesSeoToIdMap[pathFirstPart]) {
						return {
							...routeInfo,
							pageId: errorPagesIds.NOT_FOUND,
						}
					}
					return routeInfo
				}

				const warmupDynamicRouteInfo = await getWarmupDynamicRouteInfo()
				if (warmupDynamicRouteInfo) {
					dangerouslyReportFirstNavigationToDynamicPageUsingFedops()
					return {
						...routeInfo,
						...warmupDynamicRouteInfo,
					}
				}

				const { url, options } = getFetchParams(
					routerFetchData,
					viewMode,
					sessionManager,
					routeInfo.relativeEncodedUrl,
					routeInfo.parsedUrl.search,
					externalBaseUrl,
					DynamicRequestTypes.PAGES
				)

				return fetchApi
					.envFetch(url, options)
					.then(async (response: Response) => {
						if (!response.ok) {
							throw response
						}
						const data = await response.json()
						const { result, exception } = data
						const {
							page: pageId,
							data: pageData,
							head: pageHeadData,
							tpaInnerRoute,
							publicData,
							redirectUrl,
						} = result

						if (redirectUrl) {
							if (isSSR(browserWindow)) {
								handleSsrRedirect(redirectUrl, pathFirstPart, routeInfo.parsedUrl!)
								return null
							}

							if (isExternalUrl(redirectUrl)) {
								browserWindow.location.assign(redirectUrl)
							}

							const relativeUrl = getRelativeUrl(redirectUrl, pathFirstPart)
							return {
								redirectUrl: relativeUrl,
							}
						}

						if (!currentRouteInfo.getCurrentRouteInfo() && browserWindow) {
							dangerouslyReportFirstNavigationToDynamicPageUsingFedops()
						}

						const errorPageId = getErrorPageId(result, exception)

						return errorPageId
							? {
									...routeInfo,
									pageId: errorPageId,
							  }
							: {
									...routeInfo,
									pageId,
									dynamicRouteData: {
										pageData,
										pageHeadData,
										publicData,
										tpaInnerRoute,
									},
							  }
					})
					.catch(() => {
						if (isSSR(browserWindow)) {
							return null
						}

						return {
							...routeInfo,
							pageId: errorPagesIds.INTERNAL_ERROR,
						}
					})
			}

			return routeInfo
		},
	}
}

export const DynamicPages = withDependencies(
	[
		named(SiteFeatureConfigSymbol, name),
		BrowserWindowSymbol,
		SessionManagerSymbol,
		HeadContentSymbol,
		Fetch,
		CurrentRouteInfoSymbol,
		UrlHistoryManagerSymbol,
		WarmupDataProviderSymbol,
		ExperimentsSymbol,
		LoggerSymbol,
	],
	dynamicPagesFactory
)
